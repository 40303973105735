<template>
  <div>
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ $t('items_list.itemsList') }}</h3>
      </div>
    </div>
    <div class="page-content">
      <!--      <div class="filter">
              <div class="form-group filter-search-input">
                <input v-on:keyup="onSearch" class="form-control" :placeholder="$t('items_list.searchItem')"
                       v-model="serverParams.searchTerm"/>
                <span class="icon"><i class="bi bi-search"></i></span>
              </div>
            </div>-->
      <div class="card mt-4">
        <div class="card-body">
          <div class="filter">
            <div>
              <div class="row">
                <div class="col-12">
                  <div>
                    <label class="form-label">{{ $t('items_list.search') }}:</label>
                    <input class="form-control filter-input" v-model="filters.searchTerm" @keyup="onSearch">
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-12 col-md-5">
                      <div class="mt-3">
                        <label class="form-label">{{ $t('items_list.type') }}:</label>
                        <VueMultiselect
                            v-model="filters.type"
                            :options="types"
                            :object="false"
                            :close-on-select="false"
                            :show-labels="true"
                            :allow-empty="true"
                            :multiple="true"
                            :preserve-search="true"
                            :max="10"
                            track-by="value"
                            modelValue="value"
                            :custom-label="translatedLabel"
                            :select-label="$t('items_list.choose')"
                            :selected-label="$t('items_list.selected')"
                            :deselect-label="$t('items_list.deselect')"
                            :placeholder="$t('items_list.select')"
                            @select="onSearch"
                            @remove="onSearch"
                        >
                          <template #singleLabel="props"><span class="option__desc"><span
                              class="option__title">{{ $t('items_types.' + props.option.label) }}</span></span>
                          </template>
                          <template #option="props">
                            <div class="option__desc"><span
                                class="option__title">{{ $t('items_types.' + props.option.label) }}</span></div>
                          </template>
                        </VueMultiselect>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div class="mt-3">
                        <label class="form-label">{{ $t('items_list.category') }}:</label>
                        <VueMultiselect
                            v-model="filters.category"
                            :options="filtersOptions.categories"
                            :object="false"
                            :close-on-select="false"
                            :show-labels="true"
                            :allow-empty="true"
                            :preserve-search="true"
                            :multiple="true"
                            :max="10"
                            :custom-label="translatedCategoriesLabel"
                            :select-label="$t('items_list.choose')"
                            :selected-label="$t('items_list.selected')"
                            :deselect-label="$t('items_list.deselect')"
                            :placeholder="$t('items_list.select')"
                            @select="onSearch"
                            @remove="onSearch"
                        >
                          <template #singleLabel="props"><span class="option__desc"><span
                              class="option__title">{{ $t('items_categories.' + props.option) }}</span></span>
                          </template>
                          <template #option="props">
                            <div class="option__desc"><span
                                class="option__title">{{ $t('items_categories.' + props.option) }}</span></div>
                          </template>
                        </VueMultiselect>
                      </div>
                    </div>
                    <div class="col-12 col-md-2">
                      <div class="mt-3">
                        <label class="form-label">{{ $t('items_list.currency') }}:</label>
                        <VueMultiselect
                            v-model="filters.currency"
                            :options="filtersOptions.currencies"
                            :object="false"
                            :close-on-select="false"
                            :show-labels="true"
                            :allow-empty="true"
                            :multiple="true"
                            :preserve-search="true"
                            :max="10"
                            :select-label="$t('items_list.choose')"
                            :selected-label="$t('items_list.selected')"
                            :deselect-label="$t('items_list.deselect')"
                            :placeholder="$t('items_list.select')"
                            @select="onSearch"
                            @remove="onSearch"
                        >
                        </VueMultiselect>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <vue-good-table
            mode: remote
            :columns="columns"
            :rows="rows"
            :total-rows="totalRows"
            :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 20,
          position: 'bottom',
          nextLabel: $t('general.next'),
          prevLabel: $t('general.previous'),
          perPageDropdownEnabled: false,
        }"
            :sort-options="{
          enabled: true,
        }"
            v-on:page-change="onPageChange"
            v-on:sort-change="onSortChange"
            v-on:column-filter="onColumnFilter"
            v-on:per-page-change="onPerPageChange"
        >
          <template #emptystate>
            <div v-if="!isLoading" style="font-weight: bold; text-align: center">
              {{ $t('general.noData') }}
            </div>
            <div v-if="isLoading" style="font-weight: bold; text-align: center">
              {{ $t('general.loadingData') }}
            </div>
          </template>
          <!-- <template #loadingContent>
            <div style="font-weight: bold; text-align: center">
              Nahrávají se data.
            </div>
          </template> -->
          <template #table-row="props">
          <span v-if="props.column.field == 'action'">
            <span v-if="true || props.row.own_product" style="font-weight: bold; float: right">
              <router-link
                  class="btn btn-primary"
                  :to="{ name: 'ItemsDetailView', params: { code: props.row.code }, query: queryString }"
                  :title="$t('items_list.showDetail')"
              ><i class="bi bi-eye"></i
              ></router-link>
            </span>
          </span>
            <span v-if="props.column.field === 'performance_data.Returns_YTD'">
              <span v-if="props.row.performance_data && (props.row.performance_data.Returns_YTD || props.row.performance_data.Returns_YTD === 0)"
                    v-html="formatPercentageWithArrow(props.row.performance_data.Returns_YTD)"></span>
              <span v-else>---</span>
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <div v-if="isSorting" id="sorting-loader-wrap">
      <div class="loader">
        <div class="loader-content">
          <div class="spinner-grow" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <p class="mt-3">Načítám...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http';

export default {
  data() {
    return {
      queryString: {
        tenant_code: window.tenant_code ?? '',
      },
      columns: [
        {
          label: this.$t('items_list.ISIN'),
          field: 'isin',
        },
        {
          label: this.$t('items_list.code'),
          field: 'code',
        },
        {
          label: this.$t('items_list.name'),
          field: 'name',
        },
        {
          label: this.$t('items_list.currency'),
          field: 'currency',
        },
        {
          label: this.$t('items_list.yieldCurrentYear'),
          field: 'performance_data.Returns_YTD',
          type: 'percentage',
          formatFn: this.formatPercentageWithArrow,
        },
        {
          label: '',
          field: 'action',
          html: true,
          sortable: false,
        },
      ],
      rows: [],
      totalRows: 0,
      isLoading: true,
      isSorting: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 20,
        //searchTerm: '',
        filters: [],
        tenant_code: window.tenant_code ?? '',
      },
      searchTimeout: null,
      searchTimeoutLimit: 400,
      filters: {
        searchTerm: '',
        isin: '',
        code: '',
        currency: [],
        country: [],
        category: [],
        type: [],
      },
      filtersOptions: {
        currencies: [],
        countries: [],
        categories: [],
      },
      types: [
        {label: 'Strategy', value: 'STRATEGY'},
        {label: 'Alternative', value: 'ALT-'},
        {label: 'Bonds', value: 'BOND-'},
        {label: 'Balanced', value: 'BAL-'},
        {label: 'Commodity', value: 'KOM-'},
        {label: 'Stock', value: 'EQ-'},
        {label: 'Cash', value: 'MM-'},
        {label: 'Real estate', value: 'REAL-'},
      ],
    };
  },

  mounted() {
    setTimeout(() => {
      this.loadItems();
    }, 500);
    this.getFilterOptions();
  },

  methods: {
    translatedLabel(option) {
      return this.$t(`items_types.${option.label}`);
    },

    translatedCategoriesLabel(option) {
      return this.$t(`items_categories.${option}`);
    },

    loadItems() {
      let self = this;
      self.isLoading = true;
      
      return new Promise((resolve) => {
          http.get('/items', {
            params: {
              ...this.serverParams
            }
          }).then((response) => {
            self.rows = response.data.items;
            self.totalRows = response.data.totalItems;
            self.isLoading = false;
            resolve();
          });
      });
    },

    buildFiltersQuery() {
      let filterTypes = this.filters.type;
      let filters = {...this.filters};
      filters.type = [];
      filterTypes.forEach((type) => {
        filters.type.push(type.value);
      });

      if (this.filters) {
        let queryString = new URLSearchParams(filters).toString();
        console.log(queryString);
        this.serverParams.filters = queryString;
        console.log(this.serverParams);
      }
    },

    getFilterOptions() {
      let self = this;
      http.get('/items/get-filter-options').then((response) => {
        self.filtersOptions = response.data;
      });
    },

    /**
     * methods to manipulate with params
     */
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    /**
     * events when manipulated with table
     */
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      this.isSorting = true;
      this.serverParams.page = 1;
      this.updateParams({
        sort: {
          field: params[0].field,
          type: params[0].type,
        },
      });
      this.loadItems()
          .then(() => {
            this.isSorting = false;
          })
    },

    onColumnFilter(params) {
      this.serverParams.page = 1;
      this.updateParams(params);
      this.loadItems();
    },

    onSearch() {
      this.serverParams.page = 1;
      let params = this.serverParams;
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      let self = this;
      this.searchTimeout = setTimeout(function () {
        self.buildFiltersQuery();
        self.updateParams(params);
        self.loadItems();
        clearTimeout(self.searchTimeout);
      }, this.searchTimeoutLimit);
    },

    formatPercentage: function (value) {
      return value ? parseFloat(value).toFixed(2) + ' %' : '---';
    },

    formatPercentageWithArrow: function (value) {
      if (value === null || value === undefined) {
        return null;
      }

      let formattedValue = parseFloat(value).toFixed(2) + ' %';

      if (value > 0) {
        formattedValue += '<span class="text-success"> <i class="bi bi-arrow-up"></i></span>';
      } else if (value < 0) {
        formattedValue += '<span class="text-danger"> <i class="bi bi-arrow-down"></i></span>';
      }

      return formattedValue;
    },
    /**
     * end of events when manipulated with table
     */
  },
};
</script>

<style lang="scss">
#sorting-loader-wrap .loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background: #ffffff80;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
