<template>
  <div>
    <div class="page-heading">
      <div class="page-title">
        <h3>{{ $t('item_detail.itemDetail') }}</h3>
      </div>
      <div>
        <router-link class="page--breadcrumbs" :to="{ name: 'ItemsIndexView', query: queryString }" title=""
        ><i class="bi bi-arrow-left me-2"></i
        >{{ $t('general.backToList') }}
        </router-link
        >
      </div>
    </div>
    <div class="page-content">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="card mb-3">
            <div class="card-body">
              <div class="row py-1 border-bottom">
                <div class="col-6">{{ $t('item_detail.name') }}:</div>
                <div class="col-6 fw-bold">{{ item?.name }}</div>
              </div>
              <div class="row py-1 border-bottom">
                <div class="col-6">{{ $t('item_detail.type') }}:</div>
                <div class="col-6">{{
                    item?.type ? (item?.type === 'STRATEGY' ? $t('item_detail.strategy') : $t('item_detail.stocks')) : '---'
                  }}
                </div>
              </div>
              <div class="row py-1 border-bottom">
                <div class="col-6">{{ $t('item_detail.ISIN') }}:</div>
                <div class="col-6">{{ item?.isin ?? '---' }}</div>
              </div>
              <div v-if="item?.source && item.source == 'EOD'" class="row py-1 border-bottom">
                <div class="col-6">{{ $t('item_detail.EodSymbol') }}:</div>
                <div class="col-6">{{ item?.eod_symbol ?? '---' }}</div>
              </div>
              <div class="row py-1 border-bottom">
                <div class="col-6">{{ $t('item_detail.market') }}:</div>
                <div class="col-6">{{ item?.market ?? '---' }}</div>
              </div>
              <div class="row py-1 border-bottom">
                <div class="col-6">{{ $t('item_detail.ticker') }}:</div>
                <div class="col-6">{{ item?.ticker ?? '---' }}</div>
              </div>
              <div class="row py-1">
                <div class="col-6">{{ $t('item_detail.currency') }}:</div>
                <div class="col-6">{{ item?.currency ?? '---' }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <!-- <div class="row mb-3">
            <InfoCardComponent
              title="Celkové zhodnocení"
              :content="
                item?.total_evaluation ? item.total_evaluation.toFixed(2) + ' %' : '---'
              "
            ></InfoCardComponent>
          </div> -->
          <div class="row">
            <div class="col-6">
              <InfoCardComponent
                  v-if="item"
                  :title="$t('item_detail.yieldFromStart')"
                  :content="
                 item.performance_data?.Returns_Total
                  ? item.performance_data.Returns_Total.toFixed(2) + ' %'
                  : '---'
              "
                  :evaluation="true"
                  :evaluation_value="item.performance_data?.Returns_Total"
                  class="mb-3"
              ></InfoCardComponent>
            </div>
            <div class="col-6">
              <InfoCardComponent
                  v-if="item"
                  :title="$t('item_detail.YTDEvaluation')"
                  :content="
                item &&  item?.performance_data?.Returns_YTD
                  ? item?.performance_data?.Returns_YTD.toFixed(2) + ' %'
                  : '---'
              "
                  :evaluation="true"
                  :evaluation_value="item.performance_data?.Returns_YTD"
              ></InfoCardComponent>
            </div>

            <!--            <div>
                          <div class="card mt-3" v-if="item && item.years_yields">
                            <div class="card-header fw-bold">{{ $t('item_detail.yieldInYears') }}</div>
                            <div class="card-body px-4 py-1">
                              <div class="row border-bottom">
                                <div class="col p-2" v-for="year in item.years_yields">
                                  {{ year.year }}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col p-2" v-for="year in item.years_yields">
                                  <strong>{{ year.value.toFixed(2) + ' %' }}</strong>
                                  <span v-if="year.value > 0" class="ms-2"><font-awesome-icon v-if="year.value > 0" icon="arrow-up"
                                                                                              class="text-success"></font-awesome-icon></span>
                                  <span v-if="year.value < 0" class="ms-2"><font-awesome-icon v-if="year.value < 0"
                                                                                              icon="arrow-down"
                                                                                              class="text-danger"></font-awesome-icon></span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>-->
          </div>
        </div>
      </div>

      <div
          v-if="item && item.performance_data"
          class="row">
        <div class="col-12">
          <div class="card mt-3">
            <div class="card-header fw-bold">{{ $t('item_detail.performance') }}</div>
            <div class="card-body px-4 py-1">
              <div class="row border-bottom">
                <div class="col p-2" v-for="performanceValue in performanceValues">
                  {{ $t('item_detail.' + performanceValue) }}
                </div>
              </div>
              <div class="row">
                <div class="col p-2" v-for="performanceValue in performanceValues">
                  <div v-if="item.performance_data && item.performance_data[performanceValue]">
                    <strong>{{ item.performance_data[performanceValue].toFixed(2) + ' %' }}</strong>

                    <span v-if="performanceValue.startsWith('Return') && item.performance_data[performanceValue] > 0"
                          class="ms-2"><font-awesome-icon
                        v-if="item.performance_data[performanceValue] > 0" icon="arrow-up"
                        class="text-success"></font-awesome-icon></span>
                    <span v-if="performanceValue.startsWith('Return') && item.performance_data[performanceValue] < 0"
                          class="ms-2"><font-awesome-icon
                        v-if="item.performance_data[performanceValue] < 0" icon="arrow-down"
                        class="text-danger"></font-awesome-icon></span>

                  </div>
                  <div v-else>
                    --
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="performance-disclaimer mt-3">* {{ $t('item_detail.performanceDisclaimer') }}</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="item"
           class="row mt-3">
        <div class="col-6">
          <div class="card mt-3">
            <div class="card-header fw-bold">{{ $t('item_detail.composition') }}</div>
            <div v-if="!item.composition.length" class="card-body card-rows-bordered px-4 py-1">
              {{ $t('general.noInformation') }}
            </div>
            <div v-else class="card-body card-rows-bordered px-4 py-1">

              <div class="row py-1 align-items-center" v-for="compositionItem in item.composition"
                   :key="compositionItem.name">
                <div class="col-6">
                  {{ compositionItem.name }}
                </div>
                <div class="col-4">
                  {{ compositionItem.isin }}
                </div>
                <div class="col-2 text-end" v-if="compositionItem.code">
                  <router-link
                      class="btn btn-primary"
                      :to="{ name: 'ItemsDetailView', params: { code: compositionItem.code }, query: queryString }"
                      :title="$t('items_list.showDetail')"
                  ><i class="bi bi-eye"></i
                  ></router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="chart-disclaimer mt-3 text-end">* {{ $t('item_detail.compositionDisclaimer') }}</div>
            </div>
          </div>
        </div>
        <div class="col-6"
             v-if="item.fundamental_data && item.fundamental_data.ETF_Data && item.fundamental_data.ETF_Data">
          <div class="card mt-3">
            <div class="card-header fw-bold">{{ $t('item_detail.sectorWeights') }}</div>
            <div class="card-body card-rows-bordered px-4 py-1">

              <div class="row py-1"
                   v-for="propertyName in Object.keys(item.fundamental_data.ETF_Data.Sector_Weights)"
                   v-bind:key="propertyName">
                <div class="col-10">{{ propertyName }}:</div>
                <div class="col-2 text-end">{{
                    item.fundamental_data.ETF_Data.Sector_Weights[propertyName]['Equity_%'] + ' %'
                  }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div v-if="item?.description" class="row mt-3">
            <InfoCardComponent
                :title="$t('item_detail.description')"
                :content="item?.description"
                :languages="languages"
                :html="true"
            ></InfoCardComponent>
          </div>
        </div>
      </div>
    </div>

    <div v-if="item">
      <ChartViewWithFiltersComponent
          ref="chartView"
          :itemFromDetail="item"
      ></ChartViewWithFiltersComponent>
    </div>
    <!--    <div v-if="item">
          <EChartViewComponent
              ref="chartView"
              :itemFromDetail="item"
          ></EChartViewComponent>
        </div>-->
    <div class="card mt-4">
      <div class="card-body">
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :pagination-options="{
            enabled: true,
            perPage: 20,
            position: 'bottom',
            nextLabel: $t('general.next'),
            prevLabel: $t('general.previous'),
            perPageDropdownEnabled: false,
          }"
            :sort-options="{
            enabled: true,
          }"
        >
          <template #emptystate v-if="!isLoading">
            <div style="font-weight: bold; text-align: center">
              {{ $t('general.noData') }}
            </div>
          </template>
          <template #emptystate v-if="isLoading">
            <div style="font-weight: bold; text-align: center">
              {{ $t('general.loadingData') }}
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http';

export default {
  data() {
    return {
      queryString: {
        tenant_code: window.tenant_code ?? '',
      },
      item: null,
      selectedFrom: null,
      selectedTo: null,
      from: null,
      to: null,
      languages: [],
      performanceValues: [
        'Returns_YTD',
        'Returns_1Y',
        'Returns_3Y',
        'Returns_5Y',
        'Returns_10Y'
      ],
      columns: [
        {
          label: this.$t('item_detail.date'),
          field: 'date',
          thClass: 'text-center',
          tdClass: 'text-center',
          formatFn: this.parseDate
        },
        {
          label: this.$t('item_detail.originalValue'),
          field: 'old_value',
          formatFn: this.formatNumber,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: this.$t('item_detail.percentageMove'),
          field: 'percentage_move',
          formatFn: this.formatPercentage,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          label: this.$t('item_detail.newValue'),
          field: 'new_value',
          formatFn: this.formatNumber,
          thClass: 'text-center',
          tdClass: 'text-center'
        }
      ],
      rows: [],
      isLoading: false,
      selectedYear: 'YTD',
      years: []
    };
  },

  mounted() {
    this.getItem();
    this.getItemData();
    let self = this;
    this.emitter.on('filterApplied', (obj) => {
      self.selectedYear = obj.year;
      self.from = obj.from;
      self.to = obj.to;
      self.getItemData();
    });
  },

  methods: {
    getItem() {
      let self = this;
      http.get('/items/' + self.$route.params.code).then((response) => {
        self.item = response.data.item;
        self.years = response.data.item.years_of_duration.reverse();
        self.years[0] = 'YTD';
        if (self.item.description && typeof self.item.description === 'object') {
          //const descriptionData = JSON.parse(self.item.description);
          const descriptionData = self.item.description;
          // Check if the parsed data is an object
          /*if (typeof descriptionData === 'object' && descriptionData !== null) {*/
          this.languages = Object.keys(descriptionData);
          /*} else {
            this.languages = null;
          }*/
          /*} else {
            this.languages = null;*/
        }
      });
    },

    getItemData() {
      let self = this;
      self.isLoading = true;
      if (self.selectedYear !== self.$t('chart.customPeriod')) {
        if (self.selectedYear.startsWith('YTD')) {
          self.from = new Date().getFullYear() + '-01-01';
          self.to = new Date().toISOString().split('T')[0];
        } else if (self.selectedYear === this.$t('chart.fromStart')) {
          self.customRange = true;
          self.from = self.item.foundation_date.split('T')[0];
        } else {
          self.selectedYear = self.selectedYear.slice(0, 4);
          self.from = self.selectedYear + '-01-01';
          self.to = self.selectedYear + '-12-31';
        }
      } else {
        self.from = self.selectedFrom;
        self.to = self.selectedTo;
      }

      /* if (this.selectedYear !== self.$t('chart.customPeriod')) {
         self.from =
             self.selectedYear === 'YTD'
                 ? new Date().getFullYear() + '-01-01'
                 : self.selectedYear + '-01-01';
         self.to =
             self.selectedYear === 'YTD'
                 ? new Date().getFullYear() + '-12-31'
                 : self.selectedYear + '-12-31';
       }*/
      let path = '/items/item-data?code=' + self.$route.params.code;
      if (self.from) {
        path = path + '&from=' + self.from;
      }
      if (self.to) {
        path = path + '&to=' + self.to;
      }
      http.get(path).then((response) => {
        self.rows = response.data;
        self.isLoading = false;
        self.$refs.chartView?.$refs.chart.getChartData(
            self.$route.params.code,
            self.from,
            self.to
        );
      });
    },

    formatPercentage: function (value) {
      return parseFloat(value).toFixed(2) + ' %';
    },

    formatNumber: function (value) {
      return parseFloat(value).toFixed(2);
    },

    parseDate: function (value) {
      return new Date(value).toLocaleDateString();
    }
  }
};
</script>
<style>
.card-rows-bordered .row:not(:last-child) {
  border-bottom: 1px solid #e9ecef;
}
</style>